@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
/* @import 'normalize.css'; */

body {
  font-size: 20px;
}

.markdown {
  h1 {
    border-bottom: 2px solid #ccc;
    margin: 18px 0;
    font-size: 2em;
    line-height: 1.25em;
  }

  h2 {
    border-bottom: 1px solid #ccc;
    margin: 12px 0;
    font-size: 1.6em;
  }

  h3 {
    margin: 8px 0;
    font-size: 1.4em;
  }

  p {
    margin-bottom: 8px;
    text-align: justify;
  }

  a {
    color: blue;
  }

  img {
    // display: inline-block;
    width: 100%;
    max-width: 800px;
    margin: 12px auto;
  }

  ul {
    list-style: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}

.boxImage {
  transition: transform ease-in-out 0.4s;

  img {
    border: 10px solid white;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
    // border-radius: 10px;
  }
}

.text-hover {
  text-decoration: none;
  background-image: linear-gradient(to right, #00bec1 0%, #00bec1 100%);
  background-size: 0px 4px;
  background-repeat: no-repeat;
  background-position: left 87%;
  padding-bottom: 5px;
  transition: background-size 1s;

  &:hover {
    background-size: 100% 4px;
  }
}

img.image-gallery-image {
  max-height: 400px !important;
  width: 100%;
}

.fullscreen img.image-gallery-image {
  max-height: calc(100vh - 160px) !important;
  width: 100%;
}

// .virtualtour .hidden {
//   animation: fadeIn 0.5s ease-in-out;
// }

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
